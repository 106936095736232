







































































import { Component, Vue } from "vue-property-decorator";
import BaseWrapper from "@/components/Base/BaseWrapper.vue";
import BaseCard from "@/components/Base/BaseCard.vue";
import BaseAvatar from "@/components/Base/BaseAvatar.vue";
import { toDisplayName } from "@/helpers/Formatter";

@Component({
    components: {
        BaseWrapper,
        BaseCard,
        BaseAvatar,
    },
})
export default class ProposerDashboard extends Vue {
    get me() {
        return this.$store.state.users.me;
    }

    get latestActivity() {
        return this.$store.state.notifications.notifications.slice(0, 3);
    }

    get hasActivities() {
        return this.latestActivity && this.latestActivity.length;
    }

    toDisplayName(user: any) {
        return toDisplayName(user)
    }
}
